exports.components = {
  "component---src-pages-fotogalerie-js": () => import("./../../../src/pages/fotogalerie.js" /* webpackChunkName: "component---src-pages-fotogalerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nova-stranka-js": () => import("./../../../src/pages/nova-stranka.js" /* webpackChunkName: "component---src-pages-nova-stranka-js" */),
  "component---src-pages-obchody-js": () => import("./../../../src/pages/obchody.js" /* webpackChunkName: "component---src-pages-obchody-js" */),
  "component---src-pages-restaurace-js": () => import("./../../../src/pages/restaurace.js" /* webpackChunkName: "component---src-pages-restaurace-js" */),
  "component---src-pages-sluzby-a-jine-js": () => import("./../../../src/pages/sluzby-a-jine.js" /* webpackChunkName: "component---src-pages-sluzby-a-jine-js" */)
}

